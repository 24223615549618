<template>
  <div>
    <section class="section gradient-banner">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-6 order-2 order-md-1 text-center text-md-left">
                    <h1 class="text-white font-weight-bold mb-4">KONSEL SIAP KERJA</h1>
                    <p class="text-white mb-5">
                        Portal karir untuk masyarakat Konawe Selatan khususnya generasi muda yang ingin mengembangkan karirnya. Temukan beragam informasi lowongan pekerjaan hingga sarana pengembangan diri. Dari Konawe Selatan untuk Konawe Selatan.
                    </p>
                    <a href="http://adminperak.konaweselatankab.go.id/" class="btn btn-main-md" target="_blank">Daftar Sekarang</a>
                </div>
                <div class="col-md-6 text-center order-1 order-md-2">
                    <img class="img-fluid" src="assets/images/banner.png" alt="screenshot">
                </div>
            </div>
        </div>
    </section>
    <section class="section pt-0 position-relative pull-top">
        <div class="container">
            <div class="rounded shadow p-5 bg-white">
                <div class="row" style="padding-top: 20px;">
                    <div class="col-lg-4 col-md-6 mt-5 mt-md-0 text-center">
                        <h1 class="text-primary">{{jmlPendaftar}}</h1>
                        <h3 class="mt-4 text-capitalize h5 ">Jumlah Pendaftar</h3>
                    </div>
                    <div class="col-lg-4 col-md-6 mt-5 mt-md-0 text-center">
                        <h1 class="text-primary">{{jmlLoker}}</h1>
                        <h3 class="mt-4 text-capitalize h5 ">Jumlah Lowongan Pekerjaan</h3>
                    </div>
                    <div class="col-lg-4 col-md-12 mt-5 mt-lg-0 text-center">
                        <h1 class="text-primary">{{jmlMitra}}</h1>
                        <h3 class="mt-4 text-capitalize h5 ">Jumlah Perusahaan</h3>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="service">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-title">
                        <h2>Konsel Siap Kerja</h2>
                    </div>
                </div>
            </div>
            <div class="row no-gutters">
                <div class="col-lg-6">
                    <!-- Feature Image -->
                    <div class="text-center">
                        <img class="img-fluid" src="assets/images/banner1.png" alt="iphone-ipad">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="">
                        <p class="text-justify" style="padding: 20px; border-radius 4px; margin-right: 50px;">
                        Sistem Informasi Aplikasi Pelayanan Ketenaga Kerjaan (SIAP KERJA) adalah sebuah sistem yang dibuat oleh
                        Dinas Tenaga Kerja dan Transmigrasi Kabupaten Konawe Selatan, dengan tujuan dapat memberikan pelayanan terbaik kepada masyarakat, pencari kerja
                        dan perusahaan ataupun instansi yang berkaitan dengan ketenaga kerjaan.
                    </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="section page-title bg-gray">
        <div class="container">
            <div class="row">
                <div class="col-sm-8 m-auto">
                    <h1>Berita Terbaru</h1>
                </div>
            </div>
        </div>
    </section>
    <section class="post-grid section pt-0 bg-gray">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6" v-for="data in list_berita" :key="data.id">
                    <article class="post-sm">
                        <div class="post-thumb">
                            <img class="w-100" :src="file_path+data.file" style="height: 350px">
                        </div>
                        <div class="post-meta">
                            <ul class="list-inline post-tag">
                                <li class="list-inline-item">
                                    <img src="icon3.png">
                                </li>
                                <li class="list-inline-item">
                                    <a href="#">{{data.nama}}</a>
                                </li>
                                <li class="list-inline-item">
                                    {{UMUM.tglConvert(data.createAt)}}
                                </li>
                            </ul>
                        </div>
                        <div class="post-title">
                            <h3><a @click="pushBerita(data.id)" href="javascript:void(0);">{{data.judul}}</a></h3>
                        </div>
                        <div class="post-details">
                            <p>{{data.gaji}}</p>
                        </div>
                    </article>
                </div>
                <div class="col-md-12 text-center">
                    <router-link to="/berita" class="mt-3 btn btn-main-md">
                        Berita Lainnya
                    </router-link>
                </div>
            </div>
        </div>
    </section>
    <section class="section page-title">
        <div class="container">
            <div class="row">
                <div class="col-sm-8 m-auto">
                    <h1>Lowongan Kerja Terbaru</h1>
                </div>
            </div>
        </div>
    </section>
    <section class="post-grid section pt-0">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6" v-for="data in list_loker" :key="data.id">
                    <article class="post-sm">
                        <div class="post-thumb">
                            <img class="w-100" :src="file_path+data.file" style="height: 350px">
                        </div>
                        <div class="post-meta">
                            <ul class="list-inline post-tag">
                                <li class="list-inline-item">
                                    <img src="icon3.png">
                                </li>
                                <li class="list-inline-item">
                                    <a href="#">{{data.nama}}</a>
                                </li>
                                <li class="list-inline-item">
                                    {{UMUM.tglConvert(data.createdAt)}}
                                </li>
                            </ul>
                        </div>
                        <div class="post-title">
                            <h3><a @click="pushData(data.id)" href="javascript:void(0);">{{data.judul}}</a></h3>
                        </div>
                        <div class="post-details">
                            <p>{{data.gaji}}</p>
                        </div>
                    </article>
                </div>
                <div class="col-md-12 text-center">
                    <router-link to="/loker" class="mt-3 btn btn-main-md">
                        Loker Lainnya
                    </router-link>
                </div>
            </div>
        </div>
    </section>
  </div>
</template>

<script>
	import UMUM from '../library/umum';

	export default {

        components: {},

		data() {
			return {
                id : this.$route.query.id,
                list_loker : [],
                list_berita : [],
                file_old : "",
				file_path : this.$store.state.url.URL_APP + "uploads/",
                
                jmlPendaftar : '',
				jmlLoker : '',
				jmlMitra : '',

				UMUM: UMUM
			}
		},


		methods: {
            getJmlPendaftar: function() {
                fetch(this.$store.state.url.URL_HOME + "jmlPendaftar", {
                    method: "POST",
                    headers: {
						"content-type": "application/json"
                    },
                })
                    .then(res => res.json())
                    .then(res_data => {
						this.jmlPendaftar = res_data.total;
						// console.log(res_data);
				});
            },

            getJmlLoker: function() {
                fetch(this.$store.state.url.URL_HOME + "jmlLoker", {
                    method: "POST",
                    headers: {
						"content-type": "application/json"
                    },
                })
                    .then(res => res.json())
                    .then(res_data => {
						this.jmlLoker = res_data.total;
						// console.log(res_data);
				});
            },

            getMitra: function() {
                fetch(this.$store.state.url.URL_HOME + "jmlMitra", {
                    method: "POST",
                    headers: {
						"content-type": "application/json"
                    },
                })
                    .then(res => res.json())
                    .then(res_data => {
						this.jmlMitra = res_data.total;
						console.log(res_data);
				});
            },

            getLoker: function() {
                fetch(this.$store.state.url.URL_LOKER , {
                    method: "GET",
                    headers: {
						"content-type": "application/json"
                    },
                })
                    .then(res => res.json())
                    .then(res_data => {
						this.list_loker = res_data;
						// console.log(res_data);
				});
            },

            getBerita: function() {
                fetch(this.$store.state.url.URL_BERITA , {
                    method: "GET",
                    headers: {
						"content-type": "application/json"
                    },
                })
                    .then(res => res.json())
                    .then(res_data => {
						this.list_berita = res_data;
						// console.log(res_data);
				});
            },

            cek_active : function(data){
                if (data == 0) {
                    return 'active'
                }
            },

            pushData : function(id){
                this.$router.push('/isiLoker?id='+id);
            },

            pushBerita : function(id){
                this.$router.push('/isiBerita?id='+id);
            },

        },
        


		mounted() {
			this.getJmlPendaftar();
			this.getJmlLoker();
			this.getMitra();
			this.getLoker();
			this.getBerita();
		},

	}

</script>

<style>

</style>